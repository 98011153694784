import PropTypes from "prop-types"
import React from "react"
import { Wrapper } from "./containers/containers"
import Footer from "./footer"
import Header from "./header"
import "./layout.css"
import Sidebar from "./Sidebar/Sidebar"

const Layout = ({ children }) => (
  <>
    <Header />
    <Sidebar/>
    <Wrapper>
      <main
        style={{
          margin: `0 auto`,
          minHeight: "100vh",
          paddingTop: 0,
        }}
      >
        {children}
      </main>
    </Wrapper>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
