// import { styled } from '@material-ui/styles';
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import MenuSvg from "../assets/images/outline-menu-24px.svg"
import { useDispatch, useSelector } from "react-redux"
import Logow2d from "../assets/images/Logo_wine2digital.png"
import { logOutFirebase } from "../redux/firebaseAction"
import EmailIcon from "@material-ui/icons/Email"

const Header = props => {
  const dispatch = useDispatch()
  const { email } = useSelector(state => ({
    ...state.mainReducer.user.user, //extract email address from nested user object
  })) //select different slices of state controlled by reducers

  const signOut = () => {
    dispatch(logOutFirebase())
    navigate("/")
  }
  const [menuToggle, setmenuToggle] = useState(false)
  const toggleMenu = () => {
    setmenuToggle(!menuToggle)
  }
  const [open, setOpen] = React.useState(false)
  return (
    <div>
      <AppBarCustom position="static">
        <ToolbarCustom>
          {/*<Logo>*/}
          <Link to="/">
            <CustomImg alt="logo" src={Logow2d} />
            {/*<span className="mi">wine2</span> <span className="aggrego">digital</span>*/}
          </Link>
          {/*</Logo>*/}
          {email && (
            <Menu>
              <Button
                onClick={signOut}
                style={{ color: "white", backgroundColor: "black" }}
                className="menu"
              >
                <span>Log out</span>
              </Button>
              <Button>
                <img
                  onClick={toggleMenu}
                  alt="menu"
                  className="burger"
                  src={MenuSvg}
                />
              </Button>
            </Menu>
          )}
        </ToolbarCustom>
      </AppBarCustom>
      <MenuDropDown className={`${menuToggle}`}>
        <div id="menu">
          <Link to="/">
            <Button className={`${menuToggle} menu`}> Dashboard </Button>
          </Link>
          <Link to="/messages">
            <Button className={`${menuToggle} menu`}> Messages </Button>
          </Link>
          <Link to="/schools">
            <Button className={`${menuToggle} menu`}> Schools </Button>
          </Link>
          <Link to="/resources">
            <Button className={`${menuToggle} menu`}> Resources </Button>
          </Link>
          <Link to="/studentsList">
            <Button className={`${menuToggle} menu`}> Students </Button>
          </Link>
          <Link to="/settings">
            <Button className={`${menuToggle} menu`}> Settings </Button>
          </Link>
        </div>
      </MenuDropDown>
    </div>
  )
}

export default Header

const AppBarCustom = styled(AppBar)`
  border-bottom: 1px solid #e0e0e0;
  box-shadow: none !important;
  font-family: "GT-Walsheim-Regular", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  color: #242424;
  line-height: 2rem;
  position: fixed;
  z-index: 9999;
`

const ToolbarCustom = styled(Toolbar)`
  background: #fff;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  //img {
  //  padding-top: 23px;
  //  padding-left: 50px;
  //}
  ul {
    margin-left: calc(40vw - 10px);

    padding-top: 20px;
  }
  button {
    padding: 0px 10px;
  }
  .burger {
    display: none;
  }
  .menu {
    font-family: "GT-Walsheim-Regular medium", sans-serif;
    font-family: "GT-Walsheim-Regular", sans-serif;

    font-weight: 400;

    font-size: 1.1rem;
    font-size: 0.9rem;

    color: #323232;
  }
  @media (min-width: 768px) and (max-width: 868px) {
    .menu {
      font-size: 0.9rem;
      padding: 0px 0px 0px 10px;
    }
  }

  @media (max-width: 768px) {
    .menu {
      display: none;
    }

    .burger {
      display: block;
      margin-bottom: 0;
    }
  }
`

const Logo = styled.div`
  min-width: 180px;
  margin-top: 10px;
  margin-left: 1.5%;
  a {
    text-decoration: none;
  }
  .mi {
    font-family: "GT-Walsheim-Regular", sans-serif;
    font-family: "GT-Walsheim-Thin", sans-serif;
    font-weight: 300;
    font-size: 2rem;
    color: black;
    margin-right: -5px;
    letter-spacing: -1px;
  }
  .aggrego {
    font-family: "GT-Walsheim-Regular", sans-serif;
    font-family: "GT-Walsheim-Medium", sans-serif;
    color: white;
    font-weight: 600;
    font-size: 2rem;
    background-color: black;
    letter-spacing: -1px;
    padding: 5px;
  }
`
const Menu = styled.div`
  .true {
    display: block;
  }
  .false {
    display: none;
  }
`

const MenuDropDown = styled.div`
  background-color: #9fa0a14a;
  display: flex;
  flex-direction: column;
  .true {
    display: block;
  }
#menu{
margin-top:80px;
}
  .false {
    display: none;
  }
`
const CustomImg = styled.img`
  max-width: 170px;
  //padding-top: 0 !important;
  padding: 5px 5px;
  margin: 0;
`
