import firebase from "../firebase/firebase"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { clearLocalState } from "./localStorage"
import { createLogger } from "redux-logger/src"


//TODO:  prevent adding articles twice- done: test with getFunction
//
function setDataonFirestore(email, title, image, slug) {
  const db = firebase.firestore()
  //get user id from redux store and create document using user id- replace partners
  return async dispatch => {
    db.collection("users")
      .doc(email)
      .collection("SavedArticles")
      .doc(slug)
      .set(
        {
          title: `${title}`,
          image: `${image}`,
          slug: `${slug}`,
          SavedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
    dispatch({
      type: "SET_DATA",
    })
  }
}

function loginFirebase(email, password) {
  return async dispatch => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user =>
        dispatch({
          type: "LOGIN",
          payload: user,
        })
      )
      .then(user => {
        dispatch(fetchResourcesUrl())
        dispatch(fetchStudentsList())
        dispatch(fetchEducatorsList())
        dispatch(fetchAllStudents())
        dispatch(fetchMessages())
        // dispatch(fetchEducator(user))
      })
      .then(dispatch(fetchResourcesList()))
      .catch(error => {
        dispatch({
          type: "ERROR",
          payload: error.message,
        })
      })
  }
}

function addEventFirestore(form) {
  return async dispatch => {
    const db = firebase.firestore()
    const auth = firebase.auth()
    //get user id from redux store and create document using user id- replace partners
    // const email = firebase.auth.currentUser()
    db.collection("eventsList")
      // .doc(form.title)
      .add(
        {
          title: `${form.title}`,
          // start: moment(`${form.startDate}`),
          // end: moment(`${form.endDate}`),
          start: `${form.startDate}`,
          end: `${form.endDate}`,
          location: `${form.location}`,
          organizer: `${form.organizers}`,
          details: `${form.details}`,
          links: `${form.links}`,
          notes: `${form.notes}`,
          keywords: `${form.keywords}`,
          // organizedByUs: `${form.organizedByUs}`,
          organizedByUs: form.organizedByUs,
          addedBy: auth.currentUser.email,
          SavedAt: firebase.firestore.FieldValue.serverTimestamp(),
          // id: Math.floor(Math.random() * 1000000000),
        }
        // { merge: true }
      )
    dispatch({
      type: "ADD_EVENT",
    })
    dispatch({
      type: "FETCH_EVENT_LIST",
    })
  }
}

function addStudentFireStore(form) {
  return async dispatch => {
    const db = firebase.firestore()
    const auth = firebase.auth()
    if (typeof form.mcqs_section_file === "object") {
      console.log("forma mcs", typeof form.mcqs_section_file)
      const mcqs_section_file = await dispatch(
        uploadFile(form.mcqs_section_file, form.name_for_certificate)
      )
      console.log("form upload", mcqs_section_file)
      form.mcqs_section_file = mcqs_section_file
    }
    if (typeof form.tasting_section_file === "object") {
      console.log("forma tasting", typeof form.tasting_section_file)
      const tasting_section_file = await dispatch(
        uploadFile(form.tasting_section_file, form.name_for_certificate)
      )
      console.log("form upload", tasting_section_file)
      form.tasting_section_file = tasting_section_file
    }
    db.collection("educatorsList")
      .doc(form.educator_email)
      .collection("students")
      .doc(`${form.candidate_no}`)
      .set(
        {
          candidate_no: form.candidate_no,
          first_name: `${form.first_name}`,
          middle_name: `${form.middle_name}`,
          family_name: `${form.family_name}`,
          name_for_certificate: `${form.name_for_certificate}`,
          gender: `${form.gender}`,
          email: `${form.email}`,
          date_of_birth: `${form.date_of_birth}`,
          resit: form.resit,
          addedBy: auth.currentUser.email,
          SavedAt: firebase.firestore.FieldValue.serverTimestamp(),
          tasting_section_score: Number(form.tasting_section_score),
          tasting_section_file: form.tasting_section_file,
          mcqs_section_score: Number(form.mcqs_section_score),
          mcqs_section_file: form.mcqs_section_file,
          educator_email: form.educator_email,
          total_score_obtained:
            Number(form.tasting_section_score) +
            Number(form.mcqs_section_score),
          total_percentage_obtained: parseFloat(
            ((Number(form.tasting_section_score) +
              Number(form.mcqs_section_score)) /
              90) *
              100
          ).toFixed(2),
        },
        { merge: true }
      )
    dispatch({
      type: "ADD_STUDENT",
    })
    dispatch(fetchAllStudents())
    dispatch(fetchStudentsList())
  }
}

function updateStudentFirestore(form) {
  return async dispatch => {
    const db = firebase.firestore()
    const auth = firebase.auth()
    //get user id from redux store and create document using user id- replace partners
    // const email = firebase.auth.currentUser()
    console.log("form", form)
    db.collection("educatorsList")
      .doc(form.user_email)
      .collection("students")
      .doc(form.name_for_certificate)
      .set(
        {
          candidate_no: form.candidate_no,
          first_name: `${form.first_name}`,
          middle_name: `${form.middle_name}`,
          family_name: `${form.middle_name}`,
          name_for_certificate: `${form.middle_name}`,
          gender: `${form.middle_name}`,
          email: `${form.middle_name}`,
          date_of_birth: `${form.middle_name}`,
          resit: form.resit,
          addedBy: auth.currentUser.email,
          SavedAt: firebase.firestore.FieldValue.serverTimestamp(),
          tasting_section_score: Number(form.mcqs_section_score),
          mcqs_section_score: Number(form.mcqs_section_score),
          total_score_obtained:
            Number(form.tasting_section_score) +
            Number(form.mcqs_section_score),
          total_percentage_obtained: parseFloat(
            ((Number(form.tasting_section_score) +
              Number(form.mcqs_section_score)) /
              90) *
              100
          ).toFixed(2),
          // id: Math.floor(Math.random() * 1000000000),
        },
        { merge: true }
      )
    dispatch({
      type: "ADD_STUDENT",
    })
    dispatch({
      type: "FETCH_STUDENTS_LIST",
    })
  }
}

function fetchEventsList() {
  return async dispatch => {
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db.collection("eventsList").get()
    let data = []
    querySnapshot.forEach(doc => {
      data.push({ id: doc.id, ...doc.data() })
    })
    // data.map(event => {
    //     let newStartDate = moment(event.start, [
    //         "x",
    //         "DD-MM-YYYY",
    //         "MM-DD-YYYY",
    //         "YYYY-MM-DD",
    //     ])
    //     let newEndDate = moment(event.end, [
    //         "x",
    //         "DD-MM-YYYY",
    //         "MM-DD-YYYY",
    //         "YYYY-MM-DD",
    //     ])
    //     event.start = newStartDate.toDate()
    //     event.end = newEndDate.toDate()
    //     return event
    // })
    dispatch({
      type: "FETCH_EVENT_LIST",
      payload: data,
    })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
  }
}

function fetchAllStudents() {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db.collection("educatorsList").get()
    let data = []
    querySnapshot.forEach(async doc => {
      console.log("data", doc.data().email)
      let studentsCollection = await db
        .collection("educatorsList")
        .doc(doc.data().email)
        .collection("students")
        .get()
      studentsCollection.forEach(async doc => {
        data.push(doc.data())
        console.log("data", data)
      })
      dispatch({
        type: "FETCH_ALL_STUDENTS",
        payload: data,
      })
      dispatch({
        type: "TOTAL_STUDENTS_NO",
        payload: data.length,
      })
      dispatch({
        type: "FETCHING",
        payload: false,
      })
    })
  }
}

function fetchMessages() {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db
      .collection("educatorsList")
      .doc(logged_user.email)
      .collection("messages")
      .orderBy("sent_at", "desc")
      .get()
    let data = []
    querySnapshot.forEach(doc => {
      let message = {}
      const messageData = doc.data()
      const messageId = doc.id
      data.push({ messageId, messageData })
    })
    dispatch({
      type: "FETCH_MESSAGES",
      payload: data,
    })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
  }
}
function sendMessage(message_from, message_to, message) {
  return async dispatch => {
    console.log("actions", message_from, message_to, message)
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db
      .collection("educatorsList")
      .doc(message_to)
      .collection("messages")
      .add({
        from: message_from,
        to: message_to,
        message: message,
        sent_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
    dispatch(fetchMessages())
  }
}
function deleteMessage(messageId) {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db
      .collection("educatorsList")
      .doc(logged_user.email)
      .collection("messages")
      .doc(messageId)
      .delete()
    dispatch({
      type: "FETCHING",
      payload: false,
    })
    dispatch(fetchMessages())
  }
}
function fetchEducatorsList() {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db.collection("educatorsList").get()
    let data = []
    querySnapshot.forEach(doc => {
      data.push(doc.data())
    })
    dispatch({
      type: "FETCH_EDUCATORS_LIST",
      payload: data,
    })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
    dispatch({
      type: "IS_ADMIN",
    })
  }
}

function fetchEducator(email) {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const data = await db
      .collection("educatorsList")
      .doc(email)
      .get()

    dispatch({
      type: "FETCH_EDUCATOR",
      payload: data.data(),
    })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
  }
}
function saveEducator(form) {
  return async dispatch => {
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const data = await db
      .collection("educatorsList")
      .doc(form.email)
      .set(
        {
          name: form.name,
          gender: form.gender,
          email: form.email,
          location: form.location,
        },
        { merge: true }
      )
    dispatch({
      type: "FETCHING",
      payload: false,
    })
  }
}
function fetchResourcesUrl() {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db.collection("resources").get()
    let data = []
    querySnapshot.forEach(doc => {
      data.push(doc.data())
    })
    dispatch({
      type: "FETCH_RESOURCES_URL",
      payload: data,
    })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
  }
}

function fetchStudentsListForUser(educator) {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db
      .collection("educatorsList")
      .doc(educator)
      .collection("students")
      .get()
    let data = []
    querySnapshot.forEach(doc => {
      data.push(doc.data())
    })
    dispatch({
      type: "FETCH_STUDENTS_LIST",
      payload: data,
    })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
    dispatch({
      type: "TOTAL_STUDENTS_NO",
      payload: data.length,
    })
  }
}

function uploadFileGetUrl(file, folderName) {
  return async dispatch => {
    const db = firebase.firestore()
    dispatch({
      type: "UPLOAD_START",
      payload: true,
    })
    const storageRef = firebase.storage().ref("resources")
    const upload = await storageRef
      .child(folderName)
      .child(file.name)
      .put(file)
    const uploaded_file_url = await upload.ref.getDownloadURL()
    const saved2Firestore = await db.collection("resources").add({
      filename: file.name,
      url: uploaded_file_url,
      section: folderName,
    })
    console.log("uploaded_file_url", uploaded_file_url)
    dispatch(fetchResourcesList())
    dispatch(fetchResourcesUrl())

    dispatch({
      type: "FETCHING",
      payload: false,
    })

    return uploaded_file_url
  }
}

function uploadFile(file, folderName) {
  return async dispatch => {
    console.log("form upl act", file, folderName)
    dispatch({
      type: "UPLOAD_START",
      payload: true,
    })
    const storageRef = firebase.storage().ref("resources")
    // let uploaded_file_url = "";
    const upload = await storageRef
      .child(folderName)
      .child(file.name)
      .put(file)
    const uploaded_file_url = await upload.ref.getDownloadURL()
    console.log("uploaded_file_url", uploaded_file_url)
    dispatch(fetchResourcesList())
    dispatch({
      type: "FETCHING",
      payload: false,
    })

    return uploaded_file_url
  }
}

function fetchResourceFromFolder(folderName) {
  return async dispatch => {
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const storageRef = firebase.storage().ref(`resources/${folderName}`)
    let files = []
    console.log("wa", files)
    const temp_files = await storageRef.list()
    temp_files.items.forEach(item => {
      let doc = {}
      doc["folder"] = folderName
      doc["document"] = item.name
      item.getDownloadURL().then(url => {
        doc["url"] = url
      })
      files.push(doc)
      console.log("console", doc)
    })
    dispatch({
      type: "FETCH_RESOURCES_LIST",
      payload: files,
    })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
  }
}

function fetchResourcesList() {
  return async dispatch => {
    const storageRef = firebase.storage().ref("resources")
    let files = []
    // noinspection JSCheckFunctionSignatures
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    storageRef.listAll().then(function(res) {
      res.prefixes.forEach(function(folderRef) {
        folderRef
          .list()
          .then(function(itemRef) {
            itemRef.items.forEach(function(item) {
              let doc = {}
              doc["folder"] = folderRef.name
              doc["document"] = item.name
              item.getDownloadURL().then(url => {
                doc["url"] = url
              })
              files.push(doc)
            })
          })
          .then(
            dispatch({
              type: "FETCH_RESOURCES_LIST",
              payload: files,
            })
          )
          .then(
            dispatch({
              type: "FETCHING",
              payload: false,
            })
          )
          .catch(function(error) {})
      })
    })
  }
}

function fetchStudentsList() {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    const db = firebase.firestore()
    dispatch({
      type: "FETCHING",
      payload: true,
    })
    const querySnapshot = await db
      .collection("educatorsList")
      .doc(logged_user.email)
      .collection("students")
      .get()
    let data = []
    querySnapshot.forEach(doc => {
      data.push(doc.data())
    })
    dispatch({
      type: "FETCH_STUDENTS_LIST",
      payload: data,
    })
    dispatch({
      type: "FETCHING",
      payload: false,
    })
  }
}

function removeStudent(candidate_no, educator_email) {
  return async dispatch => {
    const logged_user = firebase.auth().currentUser
    //grab educator's name from the form
    const db = firebase.firestore()
    const toDelete = await db
      .collection("educatorsList")
      // .doc(logged_user.email)
      .doc(educator_email)
      .collection("students")
      .doc(`${candidate_no}`)
      .delete()
    dispatch(fetchAllStudents())
    dispatch(fetchStudentsList())
  }
}

function updateDataFirestore(form) {
  // const dispatch = useDispatch()
  const db = firebase.firestore()
  const auth = firebase.auth()
  //get user id from redux store and create document using user id- replace partners
  db.collection("eventsList")
    .doc(form.id)
    .update({
      title: `${form.title}`,
      // start: moment(`${form.startDate}`),
      // end: moment(`${form.endDate}`),
      start: `${form.startDate}`,
      end: `${form.endDate}`,
      location: `${form.location}`,
      organizer: `${form.organizers}`,
      details: `${form.details}`,
      links: `${form.links}`,
      notes: `${form.notes}`,
      keywords: `${form.keywords}`,
      addedBy: auth.currentUser.email,
      organizedByUs: form.organizedByUs,
      SavedAt: firebase.firestore.FieldValue.serverTimestamp(),
      // id: Math.floor(Math.random() * 1000000000),
    })
    .then(console.log("done"))

  // dispatch({
  // type: "EVENT_UPDATED",
  // payload: true,
  // })
}

function getDatafromFirestore(email) {
  return async dispatch => {
    const user = firebase.auth().currentUser

    const db = firebase.firestore()

    db.collection("users")
      .doc(user.email)
      .collection("SavedArticles")
      .get()
      .then(querySnapshot => {
        let data = []
        querySnapshot.forEach(doc => {
          data.push(doc.data())
        })
        dispatch({
          type: "GET_DATA",
          payload: data,
        })
      })
  }
}

function signUpFirebase(email, password, firstName, lastName) {
  return dispatch => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        user = firebase.auth().currentUser
        if (user) {
          user.updateProfile({
            displayName: `${firstName} ${lastName}`,
          })
          dispatch({
            type: "SIGN_UP",
            payload: { user, error: "" },
          })
        }
      })
      .catch(error => {
        dispatch({
          type: "ERROR",
          payload: error.message,
        })
      })
  }
}

function logOutFirebase(email, password) {
  return dispatch => {
    firebase
      .auth()
      .signOut()
      .then(
        dispatch({
          type: "LOGOUT",
          payload: false,
        }),
        clearLocalState()
      )
      .catch(error => {
        dispatch({
          type: "ERROR",
          payload: error.message,
        })
      })
  }
}

function authState() {
  return async dispatch => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        dispatch({
          type: "SET_ONLINE_STATUS",
          payload: user,
        })
      } else {
        dispatch({
          type: "SET_OFFLINE_STATUS",
        })
      }
    })
  }
}

export {
  getDatafromFirestore,
  setDataonFirestore,
  loginFirebase,
  logOutFirebase,
  authState,
  signUpFirebase,
  fetchEventsList,
  addEventFirestore,
  updateDataFirestore,
  addStudentFireStore,
  fetchEducatorsList,
  fetchStudentsList,
  fetchAllStudents,
  removeStudent,
  uploadFile,
  fetchResourcesList,
  fetchResourceFromFolder,
  uploadFileGetUrl,
  deleteMessage,
  sendMessage,
  fetchEducator,
  saveEducator,
}
