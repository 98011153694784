import React, { useState } from "react"
import { navigate } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import Modal from "../components/modal"
import { useSelector, useDispatch } from "react-redux"

import { fetchEventsList, loginFirebase, signUpFirebase } from "../redux/firebaseAction"

const LoginForm = () => {
  const dispatch = useDispatch()
  const [firstName, setfirstName] = useState("")
  const [showSignUpForm, setshowSignUpForm] = useState(false)
  const [lastName, setsecondName] = useState("")
  const [mail, setmail] = useState("")
  const [password, setPassword] = useState("")

  const { Error, isLoggedin } = useSelector(state => ({ ...state.mainReducer }))

  const login = event => {
    event.preventDefault()
    dispatch(loginFirebase(mail, password))
    Redirect()
    // dispatch(fetchEventsList())
  }

  // const fetchList=dispatch(fetchEventsList())
  const signUp = event => {
    event.preventDefault()
    dispatch(signUpFirebase(mail, password, firstName, lastName))
    Redirect(isLoggedin)
  }

  const Redirect = isLoggedin => {
    if (isLoggedin && !Error) {
      navigate("/")
    } else navigate("/")
  }

  return (
    <Grid container item justify="center">
      <Grid item>
        <Form>
          <div>
            <div>
              <TextField
                id="standard-name"
                margin="normal"
                label="Email"
                placeholder="Enter your full email address (e.g: nas@vinitalytour.com)"
                onChange={e => setmail(e.target.value)}
              />
            </div>
            <div>
              <TextField
                id="standard-uncontrolled"
                label="Password"
                type="password"
                placeholder="Enter Password"
                name="psw"
                required
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="loginbuttons">
              <Button variant="outlined" type="submit" onClick={login}>
                {" "}
                Login
              </Button>
              <p className="Error"> {Error}</p>
            </div>
          </div>
        </Form>
      </Grid>
    </Grid>
  )
}
export default LoginForm

const Form = styled.form`
margin-top: 40%;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding: 20px 50px 20px 50px;
  font-family: "GT-Walsheim-Regular", sans-serif;
  font-weight: 700;
  color: #323232;
  label,input {
    font-family: "GT-Walsheim-Regular", sans-serif;
    font-size: 16px;
  }
  
span{  font-family: "GT-Walsheim-Regular", sans-serif; }
  button {
    font-size: 0.7rem;

    color: white;
    text-align: right;
    background-color: black;
    // margin: 3px;
  }

  .loginbuttons {
    margin-top: 10px;
    text-align: end;
  }
  .Error {
  font-size: 8px;
    color: red;
  }
`
