// import firebase from 'firebase/app'
// import firestore from 'firebase/firestore'
// import auth from 'firebase/auth'
import "firebase/storage";

const firebase = require("firebase/app")
const storage = require("firebase/storage")
const auth = require("firebase/auth")

const firestore = require("firebase/firestore")

const firebaseConfig = {
  apiKey: "AIzaSyBJw8pxXsSZblHw2gH6g2iY8LVOIfaglOY",
  authDomain: "viaplatform.firebaseapp.com",
  databaseURL: "https://viaplatform.firebaseio.com",
  projectId: "viaplatform",
  storageBucket: "viaplatform.appspot.com",
  messagingSenderId: "802908178048",
  appId: "1:802908178048:web:1d7c9fe84168947a",
  measurementId: "G-74PY4X9RFJ"
};
if (typeof window !== "undefined") {
  // // Initialize Firebase
  firebase.initializeApp(firebaseConfig)
  firebase.firestore()
  firebase.auth()
  firebase.storage()
}
export default firebase
