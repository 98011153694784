import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { Link } from "@reach/router"
import styled from "styled-components"
import PeopleIcon from "@material-ui/icons/People"
import MessageIcon from "@material-ui/icons/Message"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import LocationCityIcon from "@material-ui/icons/LocationCity"
import SettingsIcon from "@material-ui/icons/Settings"
import DashboardIcon from "@material-ui/icons/Dashboard"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    // maxHeight: '600px',
    height: "88vh",
    // zIndex:'-1',
    top: "10px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

export default function PersistentDrawerLeft() {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <SideBarStyled id="sidebar" className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/">
            <ListItem button>
              <DashboardIcon />
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
          <Link to="/messages">
            <ListItem button>
              <MessageIcon />
              <ListItemText primary="Messages" />
            </ListItem>
          </Link>
          <Link to="/studentsList">
            <ListItem button>
              <PeopleIcon />
              <ListItemText primary="Students" />
            </ListItem>
          </Link>
          <Link to="/resources">
            <ListItem button>
              <InsertDriveFileIcon />
              <ListItemText primary="Resources" />
            </ListItem>
          </Link>
          <Link to="/schools">
            <ListItem button>
              <LocationCityIcon />
              <ListItemText primary="Schools" />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <Link to="/settings">
          <List>
            <ListItem button>
              <SettingsIcon />
              <ListItemText primary="Settings" />
            </ListItem>
          </List>
        </Link>
      </Drawer>
    </SideBarStyled>
  )
}
const SideBarStyled = styled.div`
  span {
    font-family: "GT-Walsheim-Regular", sans-serif;
    font-weight: 400;
    color: #323232;
    font-size: 0.9rem;
    text-transform: uppercase;
  }
  svg {
    font-size: 0.9rem;
    margin: 0 5px;
  }
`
