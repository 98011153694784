import React from "react"
import styled from "styled-components"
import Facebook from "../assets/images/facebook.svg"
import Insta from "../assets/images/insta.svg"
import Twitter from "../assets/images/twitter.svg"

const NavBar = styled.div`
  background: #000;
  font-weight: 500;
  font-family: "GT-Walsheim-Medium", sans-serif;
  color: #fff;
  font-size: 1rem;

  .logo {
    margin-left: 10px;
  }
  a {
    color: #fff;
  }
  a:link {
    text-decoration: none;
  }

  height: 75px;
  padding: 25px 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  #footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
  }
  #footer-links a {
    color: #fff;
    padding: 0px 15px;
  }

  @media (max-width: 794px) {
    #footer-links {
      display: none;
    }
  }
`

const Footer = props => {
  // const classes = NavBar();

  return (
    <NavBar position="static">
      <a className="logo" href="#" title=" ©miaggrego" rel="home">
        © wine2digital
      </a>
      {/*<div id="footer-links">*/}
      {/*  <a href="#"> Become a Content Partner</a>*/}
      {/*  <a href="/about/">About</a>*/}

      {/*  <a className="twitter" href="#">*/}
      {/*    <span className="screen-reader-only">*/}
      {/*      <img src={Insta} />*/}
      {/*    </span>*/}
      {/*  </a>*/}
      {/*  <a className="instagram" href="#">*/}
      {/*    <span className="screen-reader-only">*/}
      {/*      <img src={Twitter} />*/}
      {/*    </span>*/}
      {/*  </a>*/}
      {/*  <a className="instagram" href="#">*/}
      {/*    <span className="screen-reader-only">*/}
      {/*      <img src={Facebook} />*/}
      {/*    </span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </NavBar>
  )
}

export default Footer
