import styled from "styled-components"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import {
  FontFamily,
  FontFamilyText,
  FontFamilyTags,
  TextBgColor,
  TagsFont,
  MainTitleFont,
  SubTitleFont,
  TextFont,
} from "./constants"

const Title = styled.p`
   font-family:  ${FontFamily};
   font-weight: 800;
   font-size: ${props => (props.small ? "18px" : "24px")};
   font-size: ${props => (props.size)};
   //line-height: ${props => (props.small ? "20px" : "30px")};
   letter-spacing: ${props => (props.small ? "0.5px" : "")};
   // color: ${props => (props.color)};
   color: ${TextBgColor};
   margin-bottom: 10px;
`

const SectionTitle = styled.p`
  font-family: ${FontFamily};
  font-weight: 800;
  font-size: ${MainTitleFont};
  //line-height: 1.1;
 color: ${TextBgColor};
  //color: ${props => (props.color)};
`
const SubTitle = styled.p`
font-family:  ${FontFamilyText};
//color:  ${TextBgColor};
color: ${props => (props.color)};
 font-size: ${SubTitleFont};
 //line-height: 1.5;
 margin-bottom:-5px;
`
const TagsStyled = styled.p`
font-family: ${FontFamilyTags};
color:  ${TextBgColor};
font-size: ${TagsFont};
line-height: 1.5;
font-weight:700;
`
const TagContainer = styled.div`
 display:flex;
 flex-direction:row;
 p {
   padding-right: 10px;
 }
`

const CustomButton = styled(Button)`
  color: ${props => props.color};
  background-color: ${props => props.bg};
  height: 50px;
  font-size: 14px;
  padding: 5px 0;
  margin: 5px 0;
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  padding-left: ${props => props.pl};
  padding-right: ${props => props.pr};
  &:hover {
    background-color: darkgrey;
  }
  span {
    margin: 3px;
  }
`
const ShareStyled = styled(Button)`
  font-family: ${FontFamily};
  color: ${TextBgColor};
  font-weight:700;

`

// const ButtonStyled = styled(Button)`
//   font-family: ${FontFamily};
//   color: ${TextBgColor};

//
// `
const ButtonStyled = styled(Button)`
  font-family: "GT-Walsheim-Regular", sans-serif;
  color: #fff;
   color:${props => (props.text)};
  background: #000;
   background:${props => (props.bg)};
  font-weight: 700;
  font-size: 14px;
  margin: 5px;
  :hover{
  color:black;
  }
`

const Blog = styled.div`
  margin-top: 5%;
  letter-spacing: 2px;
  color: black;
  font-family:  ${FontFamilyText};
  font-size: ${TextFont};
  line-height: 1.7rem;
`
const Wrapper = styled.div`
  padding: 0px 20% 0px 20%;
  @media (max-width: 768px) {
    padding: 0%;
  }
 `

const TitleSocialWrapper = styled.div`
display:flex;
justify-content: space-between;

  @media (max-width: 768px) {
    display:block;
  }
 `

const BorderedGrid = styled(Grid)`
  margin-bottom: 10px;
  border-right: solid 1px #c9c9c945;
`

const Line = styled.hr`
background: #c9c9c945;
`

const FlatCard = styled(Card)`
      border: 0px;
      box-shadow:none;
      border-radius:1px;
      background-color:#fbfcfc;
      padding: 0px 5% 0px 5%;

`

const SocialContainer = styled.span`
display:flex;
  div {
    padding-left:5px;
  }
`

export {
  TitleSocialWrapper,
  SocialContainer,
  Line,
  BorderedGrid,
  Wrapper,
  Blog,
  ShareStyled,
  ButtonStyled,
  TagContainer,
  TagsStyled,
  SubTitle,
  Title,
  FlatCard,
  SectionTitle,
   CustomButton
}
